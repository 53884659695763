import React from "react"

import { Link } from "gatsby"
import Hero from '../hero/Hero'
import icon_01 from '../../images/2.0/landingpage_icons_v2-04.png'
import icon_02 from '../../images/2.0/landingpage_icons_v2-05.png'
import icon_03 from '../../images/2.0/landingpage_icons_v2-06.png'
/*images*/
import bck from "../../images/landingpage_images/2.0_landingpage_image_v5.jpg";
import './why_vascepa.css'

export default (props) => {

  return (
    <section className="landing-wrapper grey-bg">
      <h1 style={{display: "none" }}>The VASCEPA difference: What sets VASCEPA apart from other CV treatments?</h1>

      <div className="landing-grid-container two-column landing-grid-container-top-margin">
          <article className="landing-content">
            <img className="landing-icon" src={icon_01} alt="Heart icon"/>
            <h2 className="landing-subhead">The VASCEPA difference</h2>
            <p className="landing-copy">What sets VASCEPA apart from other treatments for CV risk reduction? </p>
            <Link className="homeBtn" to="/vascepa-difference/">Discover now</Link>
          </article>
          {props.isMobile ? <hr/> : null}
          <article className="landing-content">
            <img className="landing-icon" src={icon_02} alt="List icon"/>
            <h2 className="landing-subhead">Guidelines recommendations</h2>
            <p className="landing-copy">Leading medical societies recognize IPE as an important CV treatment option<sup>1-6</sup></p>
            <Link className="homeBtn" to="/guidelines/">Learn more</Link>
          </article>
          {props.isMobile ? <hr/> : null}
      </div>
    </section>
  )
}
