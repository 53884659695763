import WhyVascepa from "../components/2.0_why_vascepa/WhyVascepa"
import { Helmet } from "react-helmet"
import React, { Component } from "react"
import Layout from "../components/Layout"
import ISI from "../components/isi/ISI"
import Footer from "../components/footer/Footer"
import SEO from "../components/seo"

import Loader from "../components/loader"

const WhyVascepaRefs = props => {
  return (
    <p className="cv-footnote">
      <strong>References: 1. </strong>Virani SS, Morris PB, Agarwala A, et al. 2021 ACC expert consensus decision pathway on the management of ASCVD risk reduction in patients with persistent hypertriglyceridemia: a report of the American College of Cardiology Solution Set Oversight Committee. <em>J Am Coll Cardiol</em>. 2021;78(9):960-993.
      <strong> 2. </strong>Mach F, Baigent C, Catapano AL, et al. 2019 ESC/EAS Guidelines for the management of dyslipidaemias: lipid modification to reduce cardiovascular risk: The Task Force for the management of dyslipidaemias of the European Society of Cardiology (ESC) and European Atherosclerosis Society (EAS). <em>Eur Heart J.</em> 2020;41(1):111-188.
      <strong> 3. </strong>American Diabetes Association. 10. Cardiovascular Disease and Risk Management: <em>Standards of Medical Care in Diabetes—2021. Diabetes Care.</em> 2021;44(suppl 1):S125-S150.
      <strong> 4. </strong>Arnold SV, Bhatt DL, Barsness GW, et al. Clinical Management of Stable Coronary Artery Disease in Patients With Type 2 Diabetes Mellitus: A Scientific Statement From the American Heart Association. <em>Circulation</em>. 2020;141(19):e779-e806. 
      <strong> 5. </strong>Orringer CE, Jacobson TA, Maki KC. National Lipid Association Scientific Statement on the use of icosapent ethyl in statin-treated patients with elevated triglycerides and high or very-high ASCVD risk. <em>J Clin Lipidol.</em> 2019;13(6):860-872.
      <strong> 6. </strong>Garber AJ, Handelsman Y, Grunberger G, et al. Consensus Statement by the American Association of Clinical Endocrinologists and American College of Endocrinology on the Comprehensive Type 2 Diabetes Management Algorithm – <em>2020 Executive Summary. Endocr Pract.</em> 2020:26(1):107-139.
    </p>
  )
}

export default class WVascepa extends Component {
  constructor(props) {
    super(props)
    this.state = {
      unstickISI: false,
      isMobile: false,
      modal: null,
    }
  }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches
    this.setState({ isMobile: bool })
  }

  handleClick(e) {
    // console.log("clicked")
  }

  goModal = evt => {
    // let str = evt.target.dataset.modal;
    this.setState(() => ({ modal: "home" }))
  }

  modalClosed = () => {
    this.setState(() => ({ modal: null }))
  }

  _handleWaypointEnter = () => {
    // console.log("_handleWaypointEnter")
    this.setState(() => ({ unstickISI: true }))
  }

  _handleWaypointLeave = () => {
    // console.log("_handleWaypointLeave")
    this.setState(() => ({ unstickISI: false }))
  }

  render() {
    return (
      <>
        <Loader>
          <Layout>
            <SEO
              title="Why Prescribe VASCEPA?"
              keywords={[""]}
              description="Discover what sets the VASCEPA reduction of persistent cardiovascular risk apart, read recommendations from leading CV organizations, and read how other treatments on top of statin therapy have failed to prove CV risk reduction."
            />
            <WhyVascepa isMobile={this.state.isMobile} />
          </Layout>
          <ISI disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."/>
          <div className="ref-outer">
          <div className="footnotes-container">
          <div className="footnotes">
            <div className="cv-footnote">
            CV=cardiovascular.
            </div>
          </div>
        </div>
            <div className="footnotes-container">
              <div className="references">
                <WhyVascepaRefs />
              </div>
            </div>
          </div>
          <Footer />
        </Loader>
      </>
    )
  }
}
